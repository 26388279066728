import React from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import Layout from "components/layout/Layout";
import Page from "components/layout/Page";
import styled from "styled-components";
import Flex from "../components/layout/Flex";
import LoginImage from "../components/loginContent/LoginImage";

const ErrorDiv = styled.div`
	color: var(--accent-color);
	user-select: text;
`;

const LoggedInRedirect = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	console.log(params, location.search);
	const status = params.get("status");
	let errorText = null;
	if (status === "fail") {
		const time = parseInt(params.get("time"), 10);
		const errorCode = params.get("errorCode");
		console.log(time, typeof time);
		const displayTime = time ? new Date(time).toLocaleString() : "";
		errorText = (
			<ErrorDiv>
				<small>
					errorCode:
					{errorCode}
					, time:
					{displayTime}
				</small>
			</ErrorDiv>
		);
	}
	return (
		<Layout>
			<Page
				top={(
					<Flex paddingBottom="50px">
						<b>vPOP PRO</b>
					</Flex>
				)}
				middle={(
					<div style={{
						display: "flex",
						flex: 1,
						flexDirection: "column",
						width: "100%"
					}}
					>
						{ status === "success"
							? (
								<>
									<h1>
										Logged in securely!
										<br />
										You can close this window.
									</h1>
									<div>
										If you are a new user we will guide you
										through the process of creating a new account.
									</div>
								</>
							)
							: (
								<>
									<h1>
										Link Invalid!
									</h1>
									<div>
										You have used an invalid login link: it may
										have expired.
										Please try again. You can close this window.
									</div>
								</>
							)}
						<LoginImage image="close-window-icon.svg" alt="Close Window" />
						{ errorText }
					</div>
				)}
			/>
		</Layout>
	);
};

export default LoggedInRedirect;
